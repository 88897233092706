import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  title: {},
}));

export default function Title({
  children,
  gutterBottom = true,
  color = 'textPrimary',
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Typography
      variant="h6"
      color={color}
      gutterBottom={gutterBottom}
      className={classes.title}
      {...restProps}
    >
      {children}
    </Typography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};
