import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, matchPath } from 'react-router';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import MenuIcon from '@mui/icons-material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Title from './Title';
import Invitation from './Invitation';
import routes from './routes';
import { login, logout, openDrawer, selectOrganization } from './actions';
import { getSelectedOrganization } from './selectors';
import { Typography } from '@mui/material';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
    color: 'white',
    margin: 0,
  },
  toolbar: {
    //paddingRight: 24, // keep right padding when drawer closed
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  indicator: {
    backgroundColor: 'white',
  },
  tabRoot: {
    backgroundColor: theme.palette.application.blue[800],
  },
  tab: {
    fontSize: '0.7rem',
    [theme.breakpoints.up('sm')]: {
      minWidth: 130,
      width: 130,
    },
  },
  menu: {
    '& .MuiListItemIcon-root': {
      minWidth: 36,
    },
    '& .MuiListItemText-inset': {
      paddingLeft: 36,
    },
  },
  avatar: {
    backgroundColor: 'white',
  },
  menuHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontSize: '1.1rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  divider: {
    marginTop: theme.spacing(1),
  },
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(255, 255, 255, 0.85)',
    '&:hover': {
      color: 'white',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: 'white',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  })
);

const AntTabs = styled((props) => <Tabs {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  '& .MuiTabs-indicator': {
    backgroundColor: 'white',
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function NavBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const isLoading = useSelector((state) => state.loading);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const user = useSelector((state) => state.userInfo);
  const organizations = useSelector((state) => state.organizations.items);
  const organization = useSelector(getSelectedOrganization);
  const invites = useSelector((state) => state.userInfo?.invites || []);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElOrg, setAnchorElOrg] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserClick = () => {
    const userId = user.user_id.replace('auth0|', '');
    history.push(`/user/${userId}`);
  };

  const handleClickOrgMenu = (event) => {
    setAnchorElOrg(event.currentTarget);
  };

  const handleCloseOrg = () => {
    setAnchorElOrg(null);
  };

  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    dispatch(openDrawer());
  };

  const switchOrg = (orgId) => {
    if (orgId === organization.org_id) {
      handleCloseOrg();
    } else {
      dispatch(selectOrganization(orgId));
      history.push('/');
      handleCloseOrg();
    }
  };

  const siteRoutes = routes.find((r) => r.title === 'Facility').routes;
  const activeRoute = routes
    .concat(siteRoutes)
    .find((r) =>
      matchPath(props.location.pathname, { path: r.path, exact: true })
    );

  const match = matchPath(props.location.pathname, {
    path: activeRoute?.path,
    exact: true,
  });

  const facility = useSelector((state) =>
    state.facilities.items.find(
      (f) => f.facilityId === match?.params?.facilityId
    )
  );
  let title = activeRoute?.title;
  if (facility) {
    title = facility.facilityDesc;
  }

  if (!title && props.location.pathname.indexOf('docs') >= 0) {
    title = 'Documentation';
  }

  if (!title && props.location.pathname.indexOf('reports') >= 0) {
    title = 'Report';
  }

  const currentTab = Math.max(
    siteRoutes
      .filter((route) => route.tab)
      .findIndex((route) =>
        matchPath(props.location.pathname, {
          path: route?.path,
          exact: true,
        })
      ),
    0
  );

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerOpen}
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Title className={classes.title}>{title}</Title>
        {!isAuthenticated && (
          <Button variant="contained" color="primary" onClick={login}>
            Login
          </Button>
        )}
        {!!organization && (
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClickOrgMenu}
            >
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                style={{ color: 'white' }}
                badgeContent={<ExpandMore />}
              >
                <Avatar className={classes.avatar}>
                  <img
                    src={organization.org_logo}
                    alt={organization.org_nm}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Avatar>
              </Badge>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorElOrg}
              keepMounted
              open={Boolean(anchorElOrg)}
              onClose={handleCloseOrg}
              className={classes.menu}
            >
              <MenuItem
                onClick={() =>
                  history.push(`/organization/${organization.org_id}`)
                }
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                Organization Settings
              </MenuItem>
              {organizations.length > 1 && (
                <Divider className={classes.divider} />
              )}
              {organizations.length > 1 && (
                <Typography className={classes.menuHeader}>
                  Select Organization
                </Typography>
              )}
              {organizations.length > 1 &&
                organizations.map((o) => (
                  <MenuItem
                    key={o.org_id}
                    selected={o.selected}
                    onClick={() => switchOrg(o.org_id)}
                  >
                    {o.selected && (
                      <ListItemIcon>
                        <CheckIcon />
                      </ListItemIcon>
                    )}
                    <ListItemText inset={!o.selected}> {o.org_nm}</ListItemText>
                  </MenuItem>
                ))}
            </Menu>
          </div>
        )}
        {!!user && (
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Badge badgeContent={invites.length} color="error">
                <Badge
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  style={{ color: 'white' }}
                  badgeContent={<ExpandMore />}
                >
                  <Avatar alt={user.name} src={user.picture} />
                </Badge>
              </Badge>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleUserClick}>{user.email}</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
              {invites.map((invite) => (
                <MenuItem key={invite.rKey}>
                  <Invitation invite={invite} />
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
      </Toolbar>
      {!!facility && (
        <AntTabs
          value={currentTab}
          variant="scrollable"
          aria-label="simple tabs example"
          classes={{
            root: classes.tabRoot1,
          }}
        >
          {siteRoutes
            .filter((route) => route.tab)
            .filter((route) => {
              if (!route.roles || !route.roles.length) {
                return true;
              }
              return (
                route.roles.filter((role) => user.roles.includes(role)).length >
                0
              );
            })
            .map((route, index) => (
              <AntTab
                key={route.path}
                label={route.title}
                wrapped
                to={
                  route.path.replace(':facilityId', facility.facilityId) +
                  (route.dataWindow ? props.location.search : '')
                }
                component={RouterLink}
                classes={{ root: classes.tab }}
                {...a11yProps(index)}
              />
            ))}
        </AntTabs>
      )}

      <div>{isLoading && <LinearProgress varient="query" />}</div>
    </AppBar>
  );
}

export default withRouter(NavBar);
