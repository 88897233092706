import createAuth0Client from '@auth0/auth0-spa-js';
import qs from 'qs';
import subHours from 'date-fns/subHours';
import addHours from 'date-fns/addHours';
import getTime from 'date-fns/getTime';
import browserHistory from './history';
import { getSelectedOrganization, getOrganizations } from './selectors';
import { Organization, Facility } from './types';
import { persistor } from './store';

let auth0Client;
const localStorageOrgId = 'sswm.organization.selected';

const selected = localStorage.getItem(localStorageOrgId);
if (selected && selected.indexOf('[') !== 0) {
  localStorage.setItem(localStorageOrgId, JSON.stringify([selected]));
}

function onRedirectCallback(appState) {
  browserHistory.push(appState && appState.targetUrl ? appState.targetUrl : '');
}

function getPageParams(
  page: number,
  lastPage: number,
  size: number,
  items: any[],
  timeAttr: string
) {
  if (page === 0) {
    return { sort: 'desc', size };
  }
  const item = page < lastPage ? items[0] : items[items.length - 1];
  const sort = page > lastPage ? 'desc' : 'asc';
  const lastKey = JSON.stringify({
    rKey: item.rKey,
    org_id: item.org_id,
    [timeAttr]: item[timeAttr],
  });
  return { lastKey, sort, size };
}

async function fetcher(url: string) {
  const token = await auth0Client.getTokenSilently();
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const responseData = await response.json();
  return responseData;
}

const checkOrganization = (organization: Organization) => {
  let orgs: string | null = localStorage.getItem(localStorageOrgId);
  if (orgs === null) {
    orgs = JSON.stringify([organization.org_id]);
    localStorage.setItem(localStorageOrgId, orgs);
  }
  const selected = JSON.parse(orgs);
  organization.selected = selected.includes(organization.org_id);
  return organization;
};

export const setOrganization = (organization: Organization) => {
  organization = checkOrganization(organization);
  return {
    type: 'SET_ORGANIZATION',
    organization,
  };
};

export const setOrganizations = (organizations) => {
  organizations = organizations.map(checkOrganization);
  return {
    type: 'SET_ORGANIZATIONS',
    organizations,
  };
};

export const clearOrganizations = () => ({
  type: 'CLEAR_ORGANIZATIONS',
});

export const setFacilities = (facilities: Facility[]) => ({
  type: 'SET_FACILITIES',
  facilities,
});

export const setSites = (sites) => ({
  type: 'SET_SITES',
  sites,
});

export const setUpdates = (updates) => ({
  type: 'SET_UPDATES',
  updates,
});

export const setShadows = (shadows) => ({
  type: 'SET_SHADOWS',
  shadows,
});

export const setShadow = (shadow) => ({
  type: 'SET_SHADOW',
  shadow,
});

export const clearShadows = () => ({
  type: 'CLEAR_SHADOWS',
});

export const setForecasts = (forecasts) => ({
  type: 'SET_FORECASTS',
  forecasts,
});

export const setFacilityUpdates = (facility, updates) => ({
  type: 'SET_FACILITY_UPDATES',
  updates,
  facility,
});

export const setDataWindow = (dataWindow) => ({
  type: 'SET_DATA_WINDOW',
  dataWindow,
});

export const setDataWindowData = ({ updates, forecasts, wwes }) => ({
  type: 'SET_DATA_WINDOW_DATA',
  updates,
  forecasts,
  wwes,
});

export const setFutureForecasts = (facilityId, futureForecast) => ({
  type: 'SET_FUTURE_FORECASTS',
  facilityId,
  futureForecast,
});

export const editFacilityRequest = () => ({
  type: 'EDIT_FACILITY_REQUEST',
});

export const editFacilitySuccess = (facility) => ({
  type: 'EDIT_FACILITY_SUCCESS',
  facility,
});

export const editFacilityError = (error) => ({
  type: 'EDIT_FACILITY_ERROR',
  error,
});

export const editOrganizationSuccess = (organization) => ({
  type: 'EDIT_ORGANIZATION_SUCCESS',
  organization,
});

export const deleteOrganizationSuccess = (organization) => ({
  type: 'DELETE_ORGANIZATION_SUCCESS',
  organization,
});

export const addOrganizationSuccess = (organization) => ({
  type: 'ADD_ORGANIZATION_SUCCESS',
  organization,
});

export const addSiteSuccess = (site) => ({
  type: 'ADD_SITE_SUCCESS',
  site,
});

export const editSiteSuccess = (site) => ({
  type: 'EDIT_SITE_SUCCESS',
  site,
});

export const deleteSiteSuccess = (site) => ({
  type: 'DELETE_SITE_SUCCESS',
  site,
});

export const deleteFacilitySuccess = (facility) => ({
  type: 'DELETE_FACILITY_SUCCESS',
  facility,
});

export const addFacilitySuccess = (facility) => ({
  type: 'ADD_FACILITY_SUCCESS',
  facility,
});
export const loadHomeData = (org: Organization) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_LOADING',
      loading: true,
    });
    const orgId = org.org_id;
    const facilities = await dispatch(getFacilities(orgId));
    await Promise.all([
      dispatch(getUpdates(facilities)),
      dispatch(getSites(orgId)),
      dispatch(getShadows(facilities)),
      dispatch(getWetWeatherEvents(orgId, facilities, 0, 10, false)),
      dispatch(getSystemEvents(orgId, facilities, '', 0, 10)),
      dispatch(getFutureForecasts(facilities[0])),
    ]);
    const lastSeen = localStorage?.getItem('lastSeenEventTime') || 0;
    dispatch({ type: 'SET_LAST_SEEN_EVENT', lastSeen: +lastSeen });
    dispatch({
      type: 'SET_LOADING',
      loading: false,
    });
  };
};

export const getFacilities = (orgId: string) => {
  return async (dispatch) => {
    const responseData = await fetcher(
      `${process.env.REACT_APP_SERVER_URL}/facility/${orgId}`
    );
    let facilities = [];
    if (responseData && responseData.facilities) {
      facilities = responseData.facilities.sort((a, b) => {
        if (a.facilityDesc < b.facilityDesc) {
          return -1;
        }
        if (a.facilityDesc > b.facilityDesc) {
          return 1;
        }
        return 0;
      });
      dispatch(setFacilities(facilities));
    }
    return Promise.resolve(facilities);
  };
};

export const getSites = (orgId) => {
  return async (dispatch) => {
    const responseData = await fetcher(
      `${process.env.REACT_APP_SERVER_URL}/site/${orgId}`
    );
    if (responseData && responseData.sites) {
      const sites = responseData.sites.sort((a, b) => {
        if (a.site_nm < b.site_nm) {
          return -1;
        }
        if (a.site_nm > b.site_nm) {
          return 1;
        }
        return 0;
      });
      dispatch(setSites(sites));
      return Promise.resolve();
    }
  };
};

export const getUpdates = (facilities) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    async function getUpdatesForFacility(facility) {
      const responseData = await fetcher(
        `${process.env.REACT_APP_SERVER_URL}/updates/${org.org_id}/${facility.facilityId}?size=10&updateType=update`
      );
      return responseData;
    }
    const promises = facilities.map(getUpdatesForFacility);
    const responseData = await Promise.all(promises);

    const updatesPerFacility = {};
    facilities.forEach((facility, idx) => {
      let updates = [];
      const res: any = responseData[idx];
      if (res.updates) {
        updates = res.updates.filter((u) => u.updateType === 'update');
      }
      updatesPerFacility[facility.facilityId] = updates;
    });
    dispatch(setUpdates(updatesPerFacility));
    return Promise.resolve();
  };
};

export const getLastOpened = (facility) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());

    const token = await auth0Client.getTokenSilently();
    const payload = {
      query: { filter: [{ range: { lightInside: { gte: 1 } } }] },
    };
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/updates/${org.org_id}/${facility.facilityId}?size=1`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      const updates = responseData.updates;
      if (updates && updates.length) {
        const lastOpened = updates[0]?.deviceTimestamp
          ? updates[0].deviceTimestamp
          : updates[0].timestamp;
        dispatch({
          type: 'UPDATE_FACILITY_METADATA',
          facility,
          lastOpened,
        });
      }
    } catch (e) {
      return false;
    }
  };
};

export const getShadowForFacility = async (org, facility) => {
  const responseData = await fetcher(
    `${process.env.REACT_APP_SERVER_URL}/shadow/${org.org_id}/${facility.facilityId}`
  );
  return responseData;
};

export const getShadows = (facilities) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const promises = facilities.map((f) => getShadowForFacility(org, f));
    const responseDatas = await Promise.all(promises);
    const shadowsPerFacility = {};
    facilities.forEach((facility, idx) => {
      const res: any = responseDatas[idx];
      shadowsPerFacility[facility.facilityId] = res.shadow;
    });
    dispatch(setShadows(shadowsPerFacility));
    return Promise.resolve();
  };
};

export const getFutureForecasts = (facility) => {
  return async (dispatch) => {
    if (!facility) return;
    const responseData = await fetcher(
      `${process.env.REACT_APP_SERVER_URL}/weather/${facility.latitude},${facility.longitude}?provider=openweathermap`
    );
    dispatch(setFutureForecasts(facility.facilityId, responseData));
    return responseData;
  };
};

export const getForecasts = (facilities) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const params = {
      size: 1000,
      fields: [
        'precipIntensity',
        'precipProbability',
        'temperature',
        'time',
      ].join(','),
      sortAsc: false,
    };
    const queryString = qs.stringify(params);
    async function getForecastsForFacility(facility) {
      const responseData = await fetcher(
        `${process.env.REACT_APP_SERVER_URL}/forecasts/${org.org_id}/${facility.facilityId}?${queryString}`
      );
      return responseData;
    }
    const promises = facilities.map(getForecastsForFacility);
    const responseData = await Promise.all(promises);

    const forecastsPerFacility = {};
    facilities.forEach((facility, idx) => {
      let forecasts = [];
      const res: any = responseData[idx];
      if (res.forecasts) {
        forecasts = res.forecasts
          .map((f) => ({
            ...f,
            time: f.time * 1000,
          }))
          .reverse();
      }
      forecastsPerFacility[facility.facilityId] = forecasts;
    });
    dispatch(setForecasts(forecastsPerFacility));
    return Promise.resolve();
  };
};

async function getEarliestUpdate(org, facility) {
  const params = {
    size: 1,
    updateType: 'update',
  };
  const queryString = qs.stringify(params);
  const responseData = await fetcher(
    `${process.env.REACT_APP_SERVER_URL}/updates/${org.org_id}/${facility.facilityId}?${queryString}`
  );
  return responseData?.updates[0]?.timestamp;
}

async function getLatestUpdate(org, facility) {
  const params = {
    size: 1,
    updateType: 'update',
    sortAsc: true,
  };
  const queryString = qs.stringify(params);
  const responseData = await fetcher(
    `${process.env.REACT_APP_SERVER_URL}/updates/${org.org_id}/${facility.facilityId}?${queryString}`
  );
  return responseData?.updates[0]?.timestamp;
}

export const getDataWindow = (
  facility,
  { cache, reset } = { cache: true, reset: false }
) => {
  return async (dispatch, getState) => {
    if (facility) {
      dispatch({
        type: 'SET_LOADING',
        loading: true,
      });
      const org = getSelectedOrganization(getState());
      const { dataWindow } = getState();
      let newDataWindow = { ...dataWindow };
      if (reset || (!dataWindow.earliestTime && !dataWindow.latestTime)) {
        const results = await Promise.all([
          getEarliestUpdate(org, facility),
          getLatestUpdate(org, facility),
        ]);
        /*
        earliestTime                                                                 latestTime
        +------------------startTime<-----+duration(hrs)+----->endTime------------------->
      */
        newDataWindow = {
          ...dataWindow,
          earliestTime: results[1],
          latestTime: results[0],
          endTime: results[0],
          startTime: results[0] - dataWindow.duration * 60 * 60 * 1000,
        };
      }

      const params = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      if (params.duration) {
        if (Number.isInteger(+params.duration) && +params.duration <= 168) {
          newDataWindow.duration = +params.duration;
          newDataWindow.startTime =
            newDataWindow.endTime - newDataWindow.duration * 60 * 60 * 1000;
        }
      }
      if (params.time) {
        const d = new Date(+params.time);

        if (typeof d.getTime() === 'number') {
          newDataWindow.startTime = +params.time;
          newDataWindow.endTime = getTime(
            addHours(newDataWindow.startTime, newDataWindow.duration)
          );
        }
      }

      // todo check that we are at current time, not in past

      if (
        !newDataWindow.startTime ||
        newDataWindow.endTime > newDataWindow.latestTime ||
        newDataWindow.startTime < newDataWindow.earliestTime ||
        newDataWindow.startTime > newDataWindow.latestTime
      ) {
        newDataWindow.startTime = getTime(
          subHours(newDataWindow.latestTime, newDataWindow.duration)
        );
      }
      if (
        !newDataWindow.endTime ||
        newDataWindow.endTime > newDataWindow.latestTime ||
        newDataWindow.endTime < newDataWindow.startTime
      ) {
        newDataWindow.endTime = newDataWindow.latestTime;
      }

      const data = await getDataWindowData(org, facility, newDataWindow);

      const dw = {
        ...newDataWindow,
        ...data,
        facilityId: facility.facilityId,
      };
      dispatch(setDataWindow(dw));
      dispatch({
        type: 'SET_LOADING',
        loading: false,
      });
      return dw;
    }
  };
};

async function getUpdatesDataWindow(org, facility, dataWindow) {
  const params = {
    startTime: dataWindow.startTime,
    endTime: dataWindow.endTime,
    updateType: 'update',
  };
  const queryString = qs.stringify(params);
  const responseData = await fetcher(
    `${process.env.REACT_APP_SERVER_URL}/updates/${org.org_id}/${facility.facilityId}?${queryString}`
  );
  return responseData?.updates;
}
async function getForecastsDataWindow(org, facility, dataWindow) {
  const params = {
    startTime: dataWindow.startTime / 1000,
    endTime: dataWindow.endTime / 1000,
    fields: ['precipIntensity', 'temperature', 'time'].join(','),
    sortAsc: true,
    size: 1000,
  };
  const queryString = qs.stringify(params);
  const responseData = await fetcher(
    `${process.env.REACT_APP_SERVER_URL}/forecasts/${org.org_id}/${facility.facilityId}?${queryString}`
  );
  return responseData?.forecasts;
}
async function getHistoricalDataWindow(org, facility, dataWindow) {
  const params = {
    startTime: dataWindow.startTime / 1000,
    endTime: dataWindow.endTime / 1000,
    fields: ['precipIntensity', 'temperature', 'time'].join(','),
    sortAsc: true,
    size: 1000,
  };
  const queryString = qs.stringify(params);
  const responseData = await fetcher(
    `${process.env.REACT_APP_SERVER_URL}/historical/${org.org_id}/${facility.facilityId}?${queryString}`
  );
  return responseData?.historical;
}
async function getPredictionDataWindow(org, facility, dataWindow) {
  const params = {
    time: dataWindow.endTime / 1000,
  };
  const queryString = qs.stringify(params);
  const responseData = await fetcher(
    `${process.env.REACT_APP_SERVER_URL}/predictions/${org.org_id}/${facility.facilityId}?${queryString}`
  );
  if (responseData?.predictions && responseData?.predictions.length) {
    return responseData.predictions[0];
  }
  return [];
}

async function getWetWeatherEventsDataWindow(org, facility, dataWindow) {
  const params = {
    startTime: dataWindow.startTime,
    endTime: dataWindow.endTime,
    facilityId: facility.facilityId,
  };
  const queryString = qs.stringify(params);
  const responseData = await fetcher(
    `${process.env.REACT_APP_SERVER_URL}/wetWeatherEvents/${org.org_id}?${queryString}`
  );
  return responseData?.Items;
}

export const getDataWindowData = async (org, facility, newDataWindow) => {
  if (facility) {
    if (newDataWindow.startTime && newDataWindow.endTime) {
      const reqs = [
        getUpdatesDataWindow(org, facility, newDataWindow),
        getForecastsDataWindow(org, facility, newDataWindow),
        getWetWeatherEventsDataWindow(org, facility, newDataWindow),
        getHistoricalDataWindow(org, facility, newDataWindow),
        getPredictionDataWindow(org, facility, newDataWindow),
      ];

      const results = await Promise.all(reqs);

      const updates = results[0];
      const hasDeviceTimestamp =
        updates.filter((u) => typeof u.deviceTimestamp === 'undefined')
          .length === 0;
      const sortKey = hasDeviceTimestamp ? 'deviceTimestamp' : 'timestamp';
      updates.sort((a, b) => (a[sortKey] > b[sortKey] ? 1 : -1));

      const forecasts = results[1]
        ? results[1]?.map((f) => ({
            ...f,
            time: f.time * 1000,
          }))
        : [];

      const wwes = results[2].sort((a, b) =>
        a.wwe_start > b.wwe_start ? 1 : -1
      );

      const historical = results[3]
        ? results[3]?.map((f) => ({
            ...f,
            time: f.time * 1000,
          }))
        : [];

      const predictions = results[4] || [];

      return { updates, forecasts, wwes, historical, predictions };
    }
  }
};

export function getWetWeatherEvents(
  orgId,
  facilities,
  page,
  size,
  predict = true
) {
  return async (dispatch, getState) => {
    const params: any = { predict };
    if (facilities.length === 1 && facilities[0]) {
      params.facilityId = facilities[0].facilityId;
    }
    const items = getState().wwes.Items;
    const lastPage = getState().wwes.page;
    const timeAttr = 'wwe_end';
    const pageParams = getPageParams(page, lastPage, size, items, timeAttr);
    const queryString = qs.stringify({ ...params, ...pageParams });
    dispatch({
      type: 'WWES_FETCHSTART',
    });
    const responseData = await fetcher(
      `${process.env.REACT_APP_SERVER_URL}/wetWeatherEvents/${orgId}?${queryString}`
    );
    if (responseData.Items) {
      responseData.Items.sort((a, b) => (a[timeAttr] < b[timeAttr] ? 1 : -1));
    }

    dispatch({
      type: 'WWES_FETCHSUCCESS',
      page,
      ...responseData,
    });
  };
}

export function predictRunoff(facilityId, wwes) {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const token = await auth0Client.getTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/predictRunoff/${org.org_id}/${facilityId}`,
      {
        method: 'POST',
        body: JSON.stringify({ wwes }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      return responseData;
    } catch (e) {
      return false;
    }
  };
}

export async function predictRunoffLSTM(orgId, facilityId) {
  const token = await auth0Client.getTokenSilently();
  console.log('predictRunoffLSTM');
  const response = await fetch(
    `${process.env.REACT_APP_SERVER_URL}/predictRunoffLSTM/${orgId}/${facilityId}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  try {
    const responseData = await response.json();
    console.log('received LSTM predictions', responseData);
    return JSON.parse(responseData);
  } catch (e) {
    return false;
  }
}

export const getActions = (org, page, size) => {
  return async (dispatch, getState) => {
    if (org) {
      const items = getState().actions.Items;
      const lastPage = getState().actions.page;
      const timeAttr = 'action_time';
      const pageParams = getPageParams(page, lastPage, size, items, timeAttr);
      const queryString = qs.stringify(pageParams);
      dispatch({
        type: 'ACTIONS_FETCHSTART',
      });
      let url = `${process.env.REACT_APP_SERVER_URL}/action/${org.org_id}?${queryString}`;
      const responseData = await fetcher(url);
      responseData.Items.sort((a, b) => (a[timeAttr] < b[timeAttr] ? 1 : -1));

      dispatch({
        type: 'ACTIONS_FETCHSUCCESS',
        page,
        ...responseData,
      });
    }
  };
};

export const getSystemEvents = (orgId, facility, eventType, page, size) => {
  return async (dispatch, getState) => {
    const items = getState().events.Items;
    const lastPage = getState().events.page;
    const facilityId = facility ? facility.facilityId : null;
    const timeAttr = 'time';
    const pageParams = getPageParams(page, lastPage, size, items, timeAttr);
    const params = {
      ...pageParams,
      eventType,
    };

    dispatch({
      type: 'EVENTS_FETCHSTART',
    });
    const queryString = qs.stringify(params);
    const responseData = await fetcher(
      `${process.env.REACT_APP_SERVER_URL}/systemEvents/${orgId}/${
        facilityId ? facilityId : ''
      }?${queryString}`
    );

    responseData.Items.sort((a, b) => (a[timeAttr] < b[timeAttr] ? 1 : -1));

    dispatch({
      type: 'EVENTS_FETCHSUCCESS',
      page,
      ...responseData,
    });
  };
};

export const getOrganization = (orgId) => {
  return async (dispatch) => {
    const responseData = await fetcher(
      `${process.env.REACT_APP_SERVER_URL}/organization/${orgId}`
    );
    if (responseData && responseData.organization) {
      const { organization } = responseData;
      dispatch(setOrganization(organization));
    }
  };
};

export const getReport = (orgId, facilityId, month, year) => {
  return async (dispatch) => {
    dispatch({
      type: 'SET_REPORT',
      report: false,
    });
    let path = `${process.env.REACT_APP_SERVER_URL}/monthly-report/${orgId}?month=${month}&year=${year}`;
    if (facilityId && facilityId !== 'all') {
      path += `&facilityId=${facilityId}`;
    }
    return await fetcher(path);
  };
};

export const getReports = (orgId) => {
  return async (dispatch) => {
    dispatch({
      type: 'REPORTS_FETCHSTART',
    });
    let path = `${process.env.REACT_APP_SERVER_URL}/monthly-reports/${orgId}`;

    const responseData = await fetcher(path);
    responseData.Items.sort((a, b) =>
      new Date(a.year, a.month).getTime() < new Date(b.year, b.month).getTime()
        ? 1
        : -1
    );

    if (responseData) {
      dispatch({
        type: 'REPORTS_FETCHSUCCESS',
        ...responseData,
      });
    }
  };
};

export const getUsersForOrg = (org) => {
  return async (dispatch) => {
    if (org) {
      const { users, roles } = await fetcher(
        `${process.env.REACT_APP_SERVER_URL}/users/${org.org_id}`
      );

      dispatch({
        type: 'SET_ORGANIZATION_USERS',
        orgId: org.org_id,
        users,
        roles,
      });
      return true;
    }
  };
};

export const getInvitesForOrg = (org) => {
  return async (dispatch) => {
    if (org) {
      const responseData = await fetcher(
        `${process.env.REACT_APP_SERVER_URL}/invites/${org.org_id}`
      );
      if (responseData && responseData.invites) {
        dispatch({
          type: 'SET_ORGANIZATION_INVITES',
          orgId: org.org_id,
          invites: responseData.invites,
        });
        return true;
      }
    }
  };
};

export const selectOrganization = (orgId) => {
  return (dispatch) => {
    localStorage.setItem(localStorageOrgId, JSON.stringify([orgId]));
    dispatch({
      type: 'SELECT_ORGANIZATION',
      orgId,
    });
  };
};

export const initAuth0 = (initOptions) => {
  return async (dispatch) => {
    dispatch({
      type: 'AUTHENTICATING',
    });
    dispatch({
      type: 'SET_LOADING',
      loading: true,
    });
    try {
      auth0Client = await createAuth0Client(initOptions);
      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0Client.handleRedirectCallback();
        onRedirectCallback(appState);
      }
      await auth0Client.getTokenSilently();
      const isAuthenticated = await auth0Client.isAuthenticated();
      if (isAuthenticated) {
        const user = await auth0Client.getUser();
        dispatch({
          type: 'SET_AUTHENTICATED',
          isAuthenticated,
          user,
        });
        await dispatch(getUserInfo());
        const orgs =
          user[`${process.env.REACT_APP_AUTH0_AUDIENCE}/config`]?.orgs;

        if (!orgs.length) {
          localStorage.removeItem(localStorageOrgId);
        }
        if (orgs && orgs.length) {
          const selected = JSON.parse(
            localStorage.getItem(localStorageOrgId) || '[]'
          );
          if (!selected || !orgs.includes(selected[0])) {
            localStorage.setItem(localStorageOrgId, JSON.stringify([orgs[0]]));
          }
        }
      } else {
        dispatch({
          type: 'SET_AUTHENTICATED',
          isAuthenticated,
          user: null,
        });
        dispatch({
          type: 'SET_LOADING',
          loading: false,
        });
      }
    } catch (error) {
      console.log(error);
      // Something has gone wrong when the SDK has attempted to create an
      // Auth0 client and have it set up the correct authentication status for
      // the user. In this bad state, there's not much we can do but force a
      // log out on the user so that they can log in again.
      dispatch({
        type: 'SET_AUTHENTICATED',
        isAuthenticated: false,
        user: null,
      });
      dispatch({
        type: 'SET_LOADING',
        loading: false,
      });
    }
  };
};

export const editFacility = (facility, payload) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const org = getSelectedOrganization(getState());
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/facility/${org.org_id}/${facility.facilityId}`,
      {
        method: 'PUT',
        body: JSON.stringify({ facility: payload }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      if (responseData.facility) {
        dispatch(editFacilitySuccess(responseData.facility));
      }
      return responseData.facility;
    } catch (e) {
      return false;
    }
  };
};

export const addFacility = (facility) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const org = getSelectedOrganization(getState());

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/facility/${org.org_id}`,
      {
        method: 'POST',
        body: JSON.stringify({ facility }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      if (responseData.facility) {
        dispatch(addFacilitySuccess(responseData.facility));
      }
      return responseData.facility;
    } catch (e) {
      return false;
    }
  };
};

export const deleteFacility = (facility, payload) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const token = await auth0Client.getTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/facility/${org.org_id}/${facility.facilityId}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      if (responseData.facility) {
        dispatch(deleteFacilitySuccess(responseData.facility));
      }
      return responseData.facility;
    } catch (e) {
      return false;
    }
  };
};

export const addSite = (site) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const org = getSelectedOrganization(getState());
    const body = {
      site: {
        ...site,
        site_short_name: site.site_nm.toLowerCase().replace(/ /g, '_'),
        org_id: org.org_id,
      },
    };
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/site/${org.org_id}`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      if (responseData.site) {
        dispatch(addSiteSuccess(responseData.site));
      }
      return responseData.site;
    } catch (e) {
      return false;
    }
  };
};

export const editSite = (site, payload) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const token = await auth0Client.getTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/site/${org.org_id}/${site.site_id}`,
      {
        method: 'PUT',
        body: JSON.stringify({ site: payload }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      if (responseData.site) {
        dispatch(editSiteSuccess(responseData.site));
      }
      return responseData.site;
    } catch (e) {
      return false;
    }
  };
};

export const deleteSite = (site, payload) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const token = await auth0Client.getTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/site/${org.org_id}/${site.site_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      if (responseData.site) {
        dispatch(deleteSiteSuccess(responseData.site));
      }
      return responseData.site;
    } catch (e) {
      return false;
    }
  };
};

export const editOrganization = (organization, payload, file) => {
  return async (dispatch) => {
    const token = await auth0Client.getTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/organization/${organization.org_id}`,
      {
        method: 'PUT',
        body: JSON.stringify({ organization: payload }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      if (responseData.organization) {
        const newOrganization = responseData.organization;
        //upload logo to s3
        if (responseData.presignedUrl && file) {
          const { url, fields } = responseData.presignedUrl;
          const formData = new FormData();
          formData.append('Content-Type', file.type);
          for (const key in fields) {
            formData.append(key, fields[key]);
          }
          formData.append('file', file);
          const response = await fetch(url, {
            method: 'POST',
            body: formData,
          });
          if (response.status === 200 || response.status === 204) {
            dispatch(editOrganizationSuccess(newOrganization));
            return newOrganization;
          } else {
            return false;
          }
        }
        dispatch(editOrganizationSuccess(newOrganization));
        return responseData.organization;
      }
      return false;
    } catch (e) {
      return false;
    }
  };
};

export const deleteOrganization = (organization, payload) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const org = getSelectedOrganization(getState());
    if (org.org_id === organization.org_id) {
      const orgs = getOrganizations(getState());
      dispatch(selectOrganization(orgs[0].org_id));
    }

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/organization/${organization.org_id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      if (response.status === 200) {
        const responseData = await response.json();
        if (responseData.organization) {
          dispatch(deleteOrganizationSuccess(responseData.organization));
        }
        return responseData.organization;
      } else {
        const body = await response.text();
        return { error: body };
      }
    } catch (e) {
      console.log('catch', e);
      return false;
    }
  };
};

export const addOrganization = (payload) => {
  return async (dispatch) => {
    const token = await auth0Client.getTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/organization/`,
      {
        method: 'POST',
        body: JSON.stringify({ organization: payload }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      if (responseData.organization) {
        dispatch(addOrganizationSuccess(responseData.organization));
      }
      return responseData.organization;
    } catch (e) {
      return false;
    }
  };
};

export const editOverride = (facility, payload) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const org = getSelectedOrganization(getState());
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/override/${org.org_id}/${facility.facilityId}`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      const responseData = await response.json();
      const shadowResponseData = await getShadowForFacility(org, facility);
      const shadowByFacility = {
        [facility.facilityId]: shadowResponseData.shadow,
      };
      dispatch(setShadow(shadowByFacility));
      return responseData.override;
    } catch (e) {
      return false;
    }
  };
};

export const updateUserPreferences = (preferences) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/preferences`,
      {
        method: 'POST',
        body: JSON.stringify(preferences),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    try {
      await response.json();
      await dispatch(getUserInfo());
      return true;
    } catch (e) {
      return false;
    }
  };
};

export const removeUser = (user, org) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const appMetadata = {
      ...user.app_metadata,
      sswm: {
        ...user.app_metadata.sswm,
        orgs: user.app_metadata.sswm.orgs.filter((o) => o !== org.org_id),
      },
    };
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
      method: 'POST',
      body: JSON.stringify({ app_metadata: appMetadata, userId: user.user_id }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    try {
      await response.json();
      return true;
    } catch (e) {
      return false;
    }
  };
};

export const setUserAdmin = (user, org, isAdmin) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const self = getState().userInfo;
    const admin = user.app_metadata?.sswm?.admin || [];
    const appMetadata = {
      ...user.app_metadata,
      sswm: {
        ...user.app_metadata.sswm,
        admin: isAdmin
          ? admin.concat(org.org_id)
          : admin.filter((o) => o !== org.org_id),
      },
    };
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/users`, {
      method: 'POST',
      body: JSON.stringify({ app_metadata: appMetadata, userId: user.user_id }),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    try {
      await response.json();
      if (self.user_id === user.user_id) {
        dispatch(getUserInfo());
      }
      return true;
    } catch (e) {
      return false;
    }
  };
};

export const inviteUser = (email, orgId) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/invite`, {
        method: 'POST',
        body: JSON.stringify({ email, orgId }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        return { error: false };
      } else {
        const body = await res.text();
        return { error: body };
      }
    } catch (e) {
      return { error: 'Please Try Again.' };
    }
  };
};

export const respondInvite = (invite, accept) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    const responseData = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/invite/${invite.rKey.replace(
        '|',
        '%7C'
      )}`,
      {
        method: 'PUT',
        body: JSON.stringify({ accept, orgId: invite.org_id }),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    try {
      await auth0Client.getTokenSilently({ ignoreCache: true });
      const user = await auth0Client.getUser();
      dispatch({
        type: 'SET_USER',
        user,
      });
      if (responseData) {
        dispatch(getOrganization(invite.org_id));
        dispatch(getUserInfo());
      }
      return true;
    } catch (e) {
      return false;
    }
  };
};

export const deleteInvite = (invite) => {
  return async (dispatch, getState) => {
    const token = await auth0Client.getTokenSilently();
    try {
      await fetch(
        `${process.env.REACT_APP_SERVER_URL}/invite/${invite.rKey}?orgId=${invite.org_id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return true;
    } catch (e) {
      return false;
    }
  };
};

export const getUserInfo = () => {
  return async (dispatch) => {
    const token = await auth0Client.getTokenSilently({ ignoreCache: true });
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const { invites, orgs, userInfo } = await response.json();

    try {
      dispatch({
        type: 'SET_USER_INFO',
        userInfo: { ...userInfo, invites },
      });
      // dispatch(clearOrganizations());
      dispatch(setOrganizations(orgs));
      //orgs.map((o) => dispatch(setOrganization(o)));
      return true;
    } catch (e) {
      return false;
    }
  };
};

export const login = async () => {
  try {
    await auth0Client.loginWithRedirect();
    return false;
  } catch (error) {
    return error;
  }
};

export const signup = async () => {
  try {
    await auth0Client.loginWithRedirect({ screen_hint: 'signup' });
    return false;
  } catch (error) {
    return error;
  }
};

export const logout = async () => {
  await auth0Client.logout({ returnTo: process.env.REACT_APP_AUTH0_RETURN_TO });
  persistor.purge();
};

export const openDrawer = () => ({
  type: 'SET_DRAWER_OPEN',
});

export const closeDrawer = () => ({
  type: 'SET_DRAWER_CLOSED',
});

export const setDuration = (h, duration) => {
  return (dispatch, getState) => {
    const dataWindow = getState().dataWindow;
    h.push({
      pathname: window.location.pathname,
      search: `?time=${dataWindow.startTime}&duration=${duration}`,
    });
  };
};

export const setDataWindowEndNow = (history, facility) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const { dataWindow } = getState();
    const endTime = await getEarliestUpdate(org, facility);
    dispatch(
      setDataWindow({
        ...dataWindow,
        latestTime: endTime,
      })
    );
    dispatch(setDataWindowEnd(history, endTime));
  };
};

export const setDataWindowEnd = (history, endTime) => {
  return (dispatch, getState) => {
    const dataWindow = getState().dataWindow;
    const startTime = getTime(subHours(endTime, dataWindow.duration));
    history.push({
      pathname: window.location.pathname,
      search: `?time=${startTime}&duration=${dataWindow.duration}`,
    });
  };
};

export const incrementDataWindow = (history) => {
  return (dispatch, getState) => {
    const dataWindow = getState().dataWindow;
    const current = new Date().getTime();
    if (dataWindow.endTime < current) {
      const startTime = getTime(
        addHours(dataWindow.startTime, dataWindow.duration)
      );
      const params = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      params.time = startTime.toString();
      params.duration = dataWindow.duration;
      const search = qs.stringify(params);
      history.push({
        pathname: window.location.pathname,
        search: `?${search}`,
      });
    }
  };
};

export const decrementDataWindow = (history) => {
  return (dispatch, getState) => {
    const dataWindow = getState().dataWindow;

    const startTime = getTime(
      subHours(dataWindow.startTime, dataWindow.duration)
    );

    history.push({
      pathname: window.location.pathname,
      search: `?time=${startTime}`,
    });
  };
};

export const setDataWindowPrediction = (history, hidden) => {
  return (dispatch, getState) => {
    const params = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    const search = qs.stringify({
      ...params,
      prediction: hidden ? 'true' : 'false',
    });
    history.push({
      pathname: window.location.pathname,
      search: '?' + search,
    });
  };
};

export const download = (facilityId, year, month) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const facility = getState().facilities.items.find(
      (f) => f.facilityId === facilityId
    );
    const url = `${process.env.REACT_APP_SERVER_URL}/download/${org.org_id}?facilityId=${facility.facilityId}&year=${year}&month=${month}`;
    const token = await auth0Client.getTokenSilently();
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const filename = `smartswm_updates_${facility.facilityDesc.replace(
      / /g,
      '_'
    )}_${year}_${month}.csv`;
    const csv = await response.text();
    var pom = document.createElement('a');
    var csvContent = csv; //here we load our csv data
    var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    var url2 = URL.createObjectURL(blob);
    pom.href = url2;
    pom.setAttribute('download', filename);
    pom.click();
  };
};

export const forceTarget = (facilityId) => {
  return async (dispatch, getState) => {
    const org = getSelectedOrganization(getState());
    const token = await auth0Client.getTokenSilently();
    return await fetch(
      `${process.env.REACT_APP_SERVER_URL}/forceTarget/${org.org_id}/${facilityId}`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
};

export const exportFacilityConfig = (pond) => {
  return async (dispatch, getState) => {
    const str = JSON.stringify(pond.toDBObject());
    const filename = `smartswm_config_${pond.facilityDesc.replace(
      / /g,
      '_'
    )}.json`;
    var pom = document.createElement('a');
    var blob = new Blob([str], { type: 'text/json;charset=utf-8;' });
    var url = URL.createObjectURL(blob);
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
  };
};
