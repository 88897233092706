import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { respondInvite } from './actions';

const useStyles = makeStyles((theme) => ({
  invite: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttons: {
    paddingTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },
  root: {
    maxWidth: 275,
    marginBottom: theme.spacing(1),
    whiteSpace: 'normal',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
}));

export default function Invitation({ invite }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  async function handleAccept() {
    setLoading(true);
    await dispatch(respondInvite(invite, true));
    setLoading(false);
  }

  async function handleDismiss() {
    setLoading(true);
    await dispatch(respondInvite(invite, false));
    setLoading(false);
  }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Organization Invite
        </Typography>
        <Typography variant="body2" component="p">
          You have been invited to join the <b>{invite.org_id}</b> organization.
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleDismiss} disabled={loading}>
          Dismiss
        </Button>
        <Button color="primary" onClick={handleAccept} disabled={loading}>
          Accept
        </Button>
      </CardActions>
    </Card>
  );
}
