import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Hidden from '@mui/material/Hidden';
import Drawer from '@mui/material/Drawer';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { openDrawer, closeDrawer, loadHomeData } from './actions';
import { getSelectedOrganization } from './selectors';
import routes from './routes';
import { useWindowSize } from './hooks';
import { RoleContainer } from './roles';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  nav: {
    paddingTop: 0,
  },
  title: {
    flexGrow: 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'white',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0,
    // marginLeft: theme.spacing(1) * -1,
    // marginRight: theme.spacing(1) * -1,
    // backgroundColor: theme.palette.primary.main,
  },
  homeLink: {
    lineHeight: 0,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  nestedRoot: {
    padding: 0,
  },
  nestedText: {
    fontSize: 13,
    wordWrap: 'break-word',
  },
  nestedIconRoot: {
    minWidth: 0,
    padding: theme.spacing(1),
  },
  nestedIcon: {
    fontSize: 18,
    minWidth: 0,
  },
  avatar: {
    backgroundColor: 'white',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  listItemRoot: {
    borderRadius: 5,
    paddingTop: 3,
    paddingBottom: 3,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.application.grayblue[700],
  },
  listItemSelected: {
    backgroundColor: theme.palette.application.grayblue[100],
    color: theme.palette.application.grayblue[900],
  },
  listItemText: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500,
  },
  listSubheader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

function DrawerListItem({
  routes,
  subroutes,
  route,
  location,
  onClick = () => {},
  toggleDrawer,
  nested,
  secondaryIcon,
  badge = 0,
}) {
  const size = useWindowSize();
  const theme = useTheme();
  const mobile = size.width < theme.breakpoints.values.sm;
  let initOpen = false;
  let localStorageOrgId;
  if (subroutes) {
    localStorageOrgId = `sswm.subRouteOpen${route.path}`;
    initOpen = localStorage.getItem(localStorageOrgId);
  }
  const classes = useStyles();
  const [open, setOpen] = useState(initOpen === 'true');
  const handleClick = (e) => {
    e.preventDefault();
    localStorage.setItem(localStorageOrgId, !open);
    setOpen(!open);
  };
  let expander = null;
  if (subroutes) {
    expander = open ? (
      <ExpandLess onClick={handleClick} />
    ) : (
      <ExpandMore onClick={handleClick} />
    );
  }
  let isSelected = !!matchPath(location.pathname, {
    path: route.path,
    exact: true,
  });

  if (subroutes) {
    subroutes.forEach((route) => {
      const match = matchPath(location.pathname, {
        path: route.path,
      });
      if (match) {
        isSelected = true;
      }
    });
  }

  let _onClick = mobile
    ? () => {
        onClick();
        toggleDrawer();
      }
    : onClick;

  const className = clsx({
    [classes.listItemSelected]: isSelected,
    [classes.listItemRoot]: true,
    [classes.nested]: nested,
  });
  let sections;
  if (subroutes && subroutes.length > 0) {
    sections = subroutes
      .map((route) => route.section)
      .filter((v, i, a) => v && a.indexOf(v) === i);

    if (sections.length) {
      sections = sections.map((section) => ({
        subheader: section,
        routes: subroutes.filter((route) => route.section === section),
      }));
    } else {
      sections = [
        {
          subheader: null,
          routes: subroutes,
        },
      ];
    }
  }
  return (
    <>
      <ListItem
        button
        component={RouterLink}
        to={route.path}
        onClick={subroutes && subroutes.length > 0 ? null : _onClick}
        className={className}
      >
        <ListItemText
          primary={route.title}
          classes={{
            primary: nested ? classes.nestedText : classes.listItemText,
          }}
        />
        {badge > 0 && (
          <Badge
            style={{ marginRight: 11 }}
            badgeContent={badge}
            color="secondary"
          ></Badge>
        )}
        {expander}
        {secondaryIcon}
      </ListItem>
      {subroutes && subroutes.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {sections.map((section) => {
              return [
                section.subheader && (
                  <ListSubheader
                    key={section.subheader}
                    className={classes.listSubheader}
                  >
                    {section.subheader}
                  </ListSubheader>
                ),
                section.routes.map((subroute) => (
                  <ListItem
                    key={subroute.path}
                    button
                    className={classes.nested}
                    component={RouterLink}
                    to={subroute.path}
                    onClick={onClick}
                  >
                    <ListItemText
                      inset
                      primary={subroute.title}
                      classes={{
                        root: classes.nestedRoot,
                        primary: classes.nestedText,
                      }}
                    />
                  </ListItem>
                )),
              ];
            })}
          </List>
        </Collapse>
      )}
    </>
  );
}

function DrawerMenu(props) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { window, ponds, sites } = props;
  const open = useSelector((state) => state.drawerOpen);
  const user = useSelector((state) => state.userInfo);
  const events = useSelector((state) => state.events.Items);
  const lastSeenEventTime = useSelector((state) => state.events.lastSeen);
  const organization = useSelector(getSelectedOrganization);

  let unseenEventCount = events.length;

  if (lastSeenEventTime) {
    unseenEventCount = events.filter(
      (event) => new Date(event.time) > new Date(lastSeenEventTime)
    ).length;
  }

  const toggleDrawer = () => {
    if (open) {
      dispatch(closeDrawer());
    } else {
      dispatch(openDrawer());
    }
  };

  const refresh = () => {
    if (organization) {
      dispatch(loadHomeData(organization));
    }
  };

  const menu = (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <RouterLink to="/" className={classes.homeLink}>
          <img src="/SmartSWM-E Final.png" alt="SmartSWM" height="50" />
        </RouterLink>
      </div>
      <List component="nav" aria-label="main" className={classes.nav}>
        <DrawerListItem
          {...props}
          route={routes.find((r) => r.title === 'System')}
          toggleDrawer={toggleDrawer}
          onClick={refresh}
        />
        <DrawerListItem
          {...props}
          route={routes.find((r) => r.title === 'Sites')}
          toggleDrawer={toggleDrawer}
          subroutes={ponds.map((p) => ({
            path: `/facility/${p.facilityId}`,
            title: p.facilityDesc,
            section: sites.find((s) => s.site_id === p.siteId)?.site_nm,
          }))}
        />
        <DrawerListItem
          {...props}
          route={routes.find((r) => r.title === 'Recent Events')}
          toggleDrawer={toggleDrawer}
          badge={unseenEventCount}
        />
        <DrawerListItem
          {...props}
          route={routes.find((r) => r.title === 'Map')}
          toggleDrawer={toggleDrawer}
        />
        <DrawerListItem
          {...props}
          route={routes.find((r) => r.title === 'Reports')}
          toggleDrawer={toggleDrawer}
        />
        <DrawerListItem
          {...props}
          route={routes.find((r) => r.title === 'Documentation')}
          toggleDrawer={toggleDrawer}
          subroutes={routes.find((r) => r.title === 'Documentation').routes}
        />
      </List>
      <Divider />
      <List>
        {!!user && (
          <DrawerListItem
            {...props}
            route={{
              path: `/user/${user.user_id?.replace('auth0|', '')}`,
              title: user.email,
            }}
            toggleDrawer={toggleDrawer}
          />
        )}
        {!!organization && (
          <DrawerListItem
            {...props}
            route={{
              path: `/organization/${organization.org_id}`,
              title: organization.org_nm,
            }}
            toggleDrawer={toggleDrawer}
          />
        )}
        <RoleContainer role={'orgadmin'}>
          <DrawerListItem
            {...props}
            route={routes.find((r) => r.title === 'Administrator')}
            toggleDrawer={toggleDrawer}
            subroutes={routes.find((r) => r.title === 'Administrator').routes}
          />
        </RoleContainer>
      </List>
      <div className={classes.title}></div>
      <Link href="https://smartswm.com">smartswm.com</Link>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <nav className={classes.drawer} aria-label="sswm menu">
      <Hidden smUp implementation="css">
        <Drawer
          container={container}
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={toggleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {menu}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {menu}
        </Drawer>
      </Hidden>
    </nav>
  );
}
export default withRouter(DrawerMenu);
